<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Confirmed" subtitle="Browse confirmed assignments for slots and roles." :readonly="$authorised('con/volunteers/access', 'read')">

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-checklist v-model="filter.roles" text="Role" tooltip="Select roles" :options="references.roles" />
		<app-content-filters-checklist v-model="filter.days" text="Day" tooltip="Select days" :options="references.days" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no confirmed assignments found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Role" />
				<app-table-column text="Badge" />
				<app-table-column text="Name" />
				<app-table-column text="Slot" />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.role.name" />
					<app-table-cell-text :text="item.badge.reference" />
					<app-table-cell-text :text="item.badge.name" />
					<app-table-cell-text :text="item.slot.name" v-if="item.role.type === $constants.volunteers.role.type.schedule" />
					<app-table-cell-text :can-empty="true" v-if="item.role.type !== $constants.volunteers.role.type.schedule" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			references: {
				days: [],
				roles: []
			},
			filter: {
				search: '',
				days: [],
				roles: []
			},
			layout: '150px 100px auto 250px',
			endpoint: 'convention/volunteers/confirmed',
			live: 'convention/volunteers/confirmed'
		}

	},

	methods: {

		onEditClick: function(assignment) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignments.Edit',
				params: {
					id: assignment.id,
					role: assignment.role.id
				}
			})

		}

	}

}

</script>

<style scoped>

</style>